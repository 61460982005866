/* eslint-env browser */
import React, { useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Form,
  ListGroup,
  Stack
} from 'react-bootstrap'
import _ from 'lodash'

import {
  selectCommon,
  getAreaStrings,
  ConfirmationModal
} from '../common'
import {
  modifyLayoutLocked
} from '../article/articleAPI'
import {
  isLayoutEditableStatus
} from '../article'
import {
  closeModal,
  openModal
} from '../article/articleSlice'

import {
  selectArticleOpenedModal,
  selectLayoutableAssigneeId,
  selectLayoutableStatus,
  selectLayoutableLockVersion,
  makeLayoutSelectorByLayoutableAndArticleId,
  makeArticleDataSelectorByArticleId
} from './layoutSlice'

const getPositionStrings = (rect) => {
  const posYString = rect.top !== null ? `${rect.top + 1}段目` : ''
  const posXString = rect.right !== null ? `${rect.right + 1}行目` : ''
  return { posYString, posXString }
}

const normalizeHeadingData = (article, layout) => {
  const headingIds = layout.headingAreas
    .map(headingArea => headingArea.id)
    .filter(id => id !== undefined)
  const normalized = layout.headingAreas.map(headingArea => {
    const lines = headingArea.id !== undefined
      ? article.headings.filter(heading => `heading-${heading.id}` === headingArea.id)
      : article.headings.filter(heading => !headingIds.includes(`heading-${heading.id}`))
    const headingType = headingArea.id !== undefined
      ? lines[0].headingType
      : 'heading'
    const [numberOfOccupyingColumns, numberOfOccupyingRows] = headingType === 'heading'
      ? [article.headingNumberOfOccupyingColumns, article.headingNumberOfOccupyingRows]
      : [lines[0].numberOfOccupyingColumns, lines[0].numberOfOccupyingRows]

    return {
      lines,
      layout: headingArea,
      headingType,
      numberOfOccupyingColumns,
      numberOfOccupyingRows
    }
  })

  return normalized
}

const ArticleLayoutLockSwitch = ({ ...props }) => {
  const openedModal = useSelector(selectArticleOpenedModal)
  const { article, layoutableType, layoutableId, currentUser } = props
  const dispatch = useDispatch()
  const assigneeId = useSelector(selectLayoutableAssigneeId)
  const isUserReadable = currentUser &&
        (currentUser.role === 'group_administrator' ||
         currentUser.role === 'supervisor' ||
         currentUser.id === assigneeId)
  const layoutableStatus = useSelector(selectLayoutableStatus)
  const layoutableLockVersion = useSelector(selectLayoutableLockVersion)
  const isLayoutableWritable = isUserReadable && isLayoutEditableStatus(layoutableStatus)

  const handleSubmit = ({
    layoutLocked
  }) => {
    const params = {
      layoutableId,
      layoutableType,
      lockVersion: layoutableLockVersion,
      article: { id: article.id, layoutLocked }
    }
    console.log('handle Submit', params)
    dispatch(modifyLayoutLocked(params))
  }
  const articleName = article.filename || article.name || article.id || ''
  const [layoutLocked, setLayoutLocked] = useState(article.layoutLocked)
  const modalSetting = {
    name: 'toggle-layout-locked',
    key: 'toggle-layout-locked',
    form: {
      initialValues: {
        layoutLocked
      },
      handleSubmit,
      props: {
        title: 'レイアウトロック変更',
        submitButtonText: '変更する',
        onClickCancel: () => dispatch(closeModal()),
        ConfirmationBody: ({ layoutLocked }) => {
          console.log('body', { layoutLocked, articleLayoutLocked: article.layoutLocked })
          const message = layoutLocked ? 'ロック' : 'ロック解除'
          return (
            <>「{articleName}」のレイアウトを{message}しますか？</>
          )
        },
        layoutLocked,
        article
      }
    }
  }
  return (
    <>
      <ConfirmationModal key={modalSetting.key} {...modalSetting} openedModal={openedModal} />
      <Form
        noValidate
        onSubmit={e =>
          console.log(e)}
      >
        <Form.Check
          type='switch'
          id='articleLayoutLockedSwitch'
          label='レイアウトロック'
          name='layoutLocked'
          defaultValue={article.layoutLocked}
          checked={article.layoutLocked}
          onChange={e => {
            e.preventDefault()
            setLayoutLocked(e.target.checked)
            console.log('before open moday', { layoutLocked, articleLayoutLocked: article.layoutLocked })
            dispatch(openModal('toggle-layout-locked'))
          }}
          disabled={!isLayoutableWritable}
        />
      </Form>
    </>
  )
}

export const ObjectProperty = ({
  ...props
}) => {
  // オブジェクトプロパティ
  if (props === undefined) {
    return
  }
  const { layoutIndex, layoutableType, showExperimentalFeatures } = props
  const { enumValues } = useSelector(selectCommon)

  const h5Style = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    fontSize: '1.1rem',
    fontWeight: 'bold'
  }

  const h6Style = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    fontSize: '1.0rem',
    fontWeight: 'bold'
  }

  const descriptionStyle = {
    padding: '0.2rem',
    margin: '0.2rem',
    border: 'solid 1px'
  }

  const createArticleTags = (article) => {
    const priority = enumValues.priorities[_.camelCase(article.priority)]
    const articleType = enumValues.articleTypes[_.camelCase(article.articleType)]
    return (
      <>
        <div>
          <h6 style={h6Style}>記事</h6>
          <div>記事ID: {article.id}</div>
          <div>名称: {article.filename || article.name || ''}</div>
          <div>記事種別: {articleType}</div>
          <div>記事優先度: {priority}</div>
          {showExperimentalFeatures &&
            <div><ArticleLayoutLockSwitch article={article} {...props} /></div>}
        </div>
      </>
    )
  }

  const headingTypeNames = { ...enumValues.headingTypes, heading: '通常見出し' }

  const createHeadingTags = (article, layout, index) => {
    const normalizedHeading = normalizeHeadingData(article, layout)[index]
    const title = headingTypeNames[normalizedHeading.headingType] ?? '不明な見出し'

    const { posYString, posXString } = getPositionStrings(normalizedHeading.layout)

    return (
      <>
        <div>
          <h6 style={h6Style}>{title}</h6>
          <div>配置位置: (天から: {posYString}, 右から: {posXString})</div>
          <div>段数: {normalizedHeading.numberOfOccupyingColumns}</div>
          <div>行数: {normalizedHeading.numberOfOccupyingRows}</div>

          <div>内容:</div>
          <ListGroup>
            {normalizedHeading.lines.map((h, i) => {
              const orientation = enumValues.headingOrientations[_.camelCase(h.orientation)]
              const headingType = enumValues.headingTypes[_.camelCase(h.headingType)]
              return (
                <ListGroup.Item as='li' key={i}>
                  <Stack direction='horizontal'>
                    <div>{i}: {headingType}</div>
                    <div className='ms-auto' />
                    <div>{orientation}, {h.content.length}文字</div>
                  </Stack>
                  <div>内容:</div>
                  <div style={descriptionStyle}>{h.content}</div>
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </div>
      </>
    )
  }

  const createPreambleTags = (article, layout) => {
    if (article.preambleContent == null) {
      return <></>
    }
    const preambleArea = layout.areas[0]
    const { posYString, posXString } = getPositionStrings(preambleArea)
    return (
      <>
        <div>
          <h6 style={h6Style}>前文</h6>
          <div>配置位置: (天から: {posYString}, 右から: {posXString})</div>
          <div>文字数: {article.preambleContent.length}</div>
          <div>段数: {article.preambleNumberOfColumns}</div>
          <div>行数: {article.preambleNumberOfRows}</div>
          <div>内容:</div>
          <div style={descriptionStyle}>{article.preambleContent}</div>
        </div>
      </>
    )
  }

  const createFlowTags = (article, layout) => {
    const textStart = article.preambleContent == null ? 0 : 1

    const articleArea = layout.areas[textStart]
    const { posYString, posXString } = getPositionStrings(articleArea)
    return (
      <>
        <div>
          <h6 style={h6Style}>本文</h6>
          <div>配置位置: (天から: {posYString}, 右から: {posXString})</div>
          <div>文字数: {article.bodyContent.length}</div>
          <div>小割数: {layout.areas.length - textStart}</div>
          <div>内容:</div>
          <div style={descriptionStyle}>{article.bodyContent}</div>
        </div>
      </>
    )
  }

  const createAreaTags = (article, layout) => {
    const area = article.areas?.[0]
    if (area === null) {
      return (<></>)
    }
    const { areaYString, areaXString, columnString, rowString } = getAreaStrings(area)
    return (
      <>
        <div>
          <h6 style={h6Style}>{enumValues.articleTypes[article.articleType]}</h6>
          <div>天から: {areaYString}</div>
          <div>右から: {areaXString}</div>
          <div>天地: {columnString}</div>
          <div>左右: {rowString}</div>
        </div>
      </>
    )
  }

  const createTemplateArticleTags = (article, layout) => {
    const area = article.areas?.[0]
    if (area === null) {
      return (<></>)
    }
    const { areaYString, areaXString, columnString, rowString } = getAreaStrings(area)
    return (
      <>
        <div>
          <h6 style={h6Style}>{enumValues.articleTypes[article.articleType]}</h6>
          <div>フレーム数: {article.templateArticles.length}</div>
          <div>天から: {areaYString}</div>
          <div>右から: {areaXString}</div>
          <div>天地: {columnString}</div>
          <div>左右: {rowString}</div>
        </div>
      </>
    )
  }

  const createFigureTags = (article, layout, index) => {
    const figure = article.figures[index]

    if (figure === undefined) {
      return <></>
    }

    const figureType = enumValues.figureTypes[_.camelCase(figure.figureType)]
    const figureArea = layout.figures[index]?.area
    if (figureArea === undefined) {
      return <></>
    }
    const { posYString, posXString } = getPositionStrings(figureArea)
    return (
      <>
        <div>
          <h6 style={h6Style}>図表</h6>
          <div>図表ID: {figure.id}</div>
          <div>ファイル名: {figure?.filename}</div>
          <div>配置位置: (天から: {posYString}, 右から: {posXString})</div>
          <div>種別: {figureType}</div>
        </div>
      </>
    )
  }

  const [, articleId, objectType, indexString] = props.contentSelector === undefined
    ? [undefined, '', undefined, undefined]
    : props.contentSelector.split('.')
  const rawArticleId = Number(articleId.replace('article-', ''))
  const selectLayout = useMemo(
    () => makeLayoutSelectorByLayoutableAndArticleId(layoutableType))
  const layout = useSelector(state => selectLayout(state, layoutIndex, rawArticleId))
  const selectArticle = useMemo(() => makeArticleDataSelectorByArticleId())
  const article = useSelector(state => selectArticle(state, rawArticleId))

  const createTags = (articleId, objectType, index) => {
    let propertyTags = (
      <>
        <div>{articleId}</div>
        <div>{objectType}</div>
        <div>{index}</div>
      </>
    )

    switch (objectType) {
      case 'heading':
        propertyTags = createHeadingTags(article, layout, index)
        break
      case 'preamble':
        propertyTags = createPreambleTags(article, layout)
        break
      case 'flow':
        propertyTags = createFlowTags(article, layout)
        break
      case 'area':
        propertyTags = createAreaTags(article, layout)
        break
      case 'template':
        propertyTags = createTemplateArticleTags(article, layout)
        break
      case 'figure':
        propertyTags = createFigureTags(article, layout, index)
        break
    }

    return (
      <>
        <Stack gap='3'>
          {createArticleTags(article)}
          {propertyTags}
        </Stack>
      </>
    )
  }

  let content = (<></>)

  if (props.contentSelector === undefined) {
    content = (
      <>
        <span>未選択</span>
      </>
    )
  } else {
    const index = indexString === undefined
      ? indexString
      : parseInt(indexString.replace('index', ''))
    content = createTags(articleId, objectType, index)
  }

  return (
    <>
      <h5 style={h5Style}>オブジェクトプロパティ</h5>
      {content}
    </>
  )
}
