import { createSlice } from '@reduxjs/toolkit'
import {
  DataList,
  initialErrorState,
  saveErrorInState,
  dismissError as commonDismissError,
  closeAlert as commonCloseAlert,
  initialAlertState
} from '../common'
import {
  fetchSubLayouts,
  createSubLayout,
  modifySubLayout,
  copySubLayout,
  deleteSubLayout,
  fetchLayoutResult,
  modifyLayoutIndex,
  exportLayout
} from './subLayoutAPI'

const initialState = {
  ...{
    sort: {
      column: 'id',
      order: 'desc'
    },
    selectedIds: [],
    openedModal: null
  },
  ...DataList.initialState,
  ...initialErrorState,
  ...initialAlertState
}

export const downloadFulfilled = (state, action) => {
  const { fileName } = action.meta.arg
  const mimeType = 'application/zip'
  const data = new window.Blob([action.payload], { type: mimeType })
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.href = url
  link.setAttribute('download', fileName)
  link.click()
  document.body.removeChild(link)
}

export const subLayoutSlice = createSlice({
  name: 'subLayout',
  initialState,
  reducers: {
    selectColumn: DataList.selectColumn,
    selectPage: DataList.selectPage,
    selectId: (state, action) => {
      state.selectedIds = Array.from(new Set(state.selectedIds).add(action.payload))
    },
    unselectId: (state, action) => {
      const set = new Set(state.selectedIds)
      set.delete(action.payload)
      state.selectedIds = Array.from(set)
    },
    openModal: (state, action) => {
      state.openedModal = action.payload
    },
    closeModal: (state) => {
      state.openedModal = null
      // clear alert state
      state.alert = null
    },
    closeAlert: commonCloseAlert,
    clearLayoutResult: (state, action) => {
      delete state.layoutResult
    },
    dismissError: commonDismissError
  },
  extraReducers: builder => builder
    .addCase(fetchSubLayouts.pending, DataList.fetchPending)
    .addCase(fetchSubLayouts.fulfilled, DataList.fetchFulfilled)
    .addCase(fetchSubLayouts.rejected, saveErrorInState(DataList.fetchRejected))
    .addCase(createSubLayout.pending, state => {
      state.fetching = true
    })
    .addCase(createSubLayout.fulfilled, state => {
      state.fetching = false
    })
    .addCase(createSubLayout.rejected, (state, action) => {
      state.fetching = false
      const error = action.payload
      state.alert = {
        show: true,
        variant: 'danger',
        alertBody: error.message
      }
    })
    .addCase(modifySubLayout.pending, state => {
      state.fetching = true
    })
    .addCase(modifySubLayout.fulfilled, state => {
      state.fetching = false
    })
    .addCase(modifySubLayout.rejected, (state, action) => {
      state.fetching = false
      const error = action.payload
      if (error !== undefined) {
        state.alert = {
          show: true,
          variant: 'danger',
          alertBody: error.message
        }
      }
    })
    .addCase(deleteSubLayout.pending, state => {
      state.fetching = true
    })
    .addCase(deleteSubLayout.fulfilled, state => {
      state.fetching = false
      state.selectedIds = []
    })
    .addCase(deleteSubLayout.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(fetchLayoutResult.pending, state => {
      state.fetching = true
    })
    .addCase(fetchLayoutResult.fulfilled, (state, action) => {
      state.layoutResult = action.payload
      state.fetching = false
    })
    .addCase(fetchLayoutResult.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(modifyLayoutIndex.pending, state => {
      state.fetching = false
    })
    .addCase(modifyLayoutIndex.fulfilled, (state, action) => {
      state.layoutResult = action.payload
      state.fetching = false
    })
    .addCase(modifyLayoutIndex.rejected, saveErrorInState((state, action) => {
      state.fetching = false
      const error = action.payload
      if (error !== undefined) {
        state.alert = {
          show: true,
          variant: 'danger',
          alertBody: error.message
        }
      }
    }))
    .addCase(copySubLayout.pending, state => {
      state.fetching = true
    })
    .addCase(copySubLayout.fulfilled, state => {
      state.fetching = false
    })
    .addCase(copySubLayout.rejected, (state, action) => {
      state.fetching = false
      const error = action.payload
      if (error !== undefined) {
        state.alert = {
          show: true,
          variant: 'danger',
          alertBody: error.message
        }
      }
    })
    .addCase(exportLayout.pending, (state, action) => {
      state.fetching = true
    })
    .addCase(exportLayout.fulfilled, (state, action) => {
      downloadFulfilled(state, action)
      state.fetching = false
    })
    .addCase(exportLayout.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
})

export const selectSubLayoutItem = state => state.subLayout

export const {
  selectColumn,
  selectPage,
  selectId,
  unselectId,
  openModal,
  closeModal,
  closeAlert,
  clearLayoutResult,
  dismissError
} = subLayoutSlice.actions
