import { createSlice } from '@reduxjs/toolkit'
import {
  DataList,
  initialErrorState,
  saveErrorInState,
  dismissError as commonDismissError,
  closeAlert as commonCloseAlert,
  initialAlertState,
  setErrorAlert
} from '../common'
import {
  fetchPages,
  createPage,
  modifyPage,
  copyPage,
  deletePage,
  fetchLayoutResult,
  exportLayout
} from './pageAPI'

const initialState = {
  ...{
    sort: {
      column: 'id',
      order: 'desc'
    },
    selectedIds: [],
    searchCondition: {
      mediaId: '',
      issueDate: ''
    },
    openedModal: null
  },
  ...DataList.initialState,
  ...initialErrorState,
  ...initialAlertState
}

export const downloadFulfilled = (state, action) => {
  const { fileName } = action.meta.arg
  const mimeType = 'application/zip'
  const data = new window.Blob([action.payload], { type: mimeType })
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.href = url
  link.setAttribute('download', fileName)
  link.click()
  document.body.removeChild(link)
}

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    selectColumn: DataList.selectColumn,
    selectPage: DataList.selectPage,
    selectId: (state, action) => {
      state.selectedIds = Array.from(new Set(state.selectedIds).add(action.payload))
    },
    unselectId: (state, action) => {
      const set = new Set(state.selectedIds)
      set.delete(action.payload)
      state.selectedIds = Array.from(set)
    },
    openModal: (state, action) => {
      state.openedModal = action.payload
    },
    closeModal: (state) => {
      state.openedModal = null
      // clear alert state
      state.alert = null
    },
    setSearchCondition: (state, action) => {
      const { name, value } = action.payload
      state.searchCondition[name] = value
    },
    clearLayoutResult: (state, action) => {
      delete state.layoutResult
    },
    closeAlert: commonCloseAlert,
    dismissError: commonDismissError
  },
  extraReducers: builder => builder
    .addCase(fetchPages.pending, DataList.fetchPending)
    .addCase(fetchPages.fulfilled, DataList.fetchFulfilled)
    .addCase(fetchPages.rejected, saveErrorInState(DataList.fetchRejected))
    .addCase(createPage.pending, state => {
      state.fetching = true
    })
    .addCase(createPage.fulfilled, state => {
      state.fetching = false
    })
    .addCase(createPage.rejected, setErrorAlert(state => {
      state.fetching = false
    }))
    .addCase(modifyPage.pending, state => {
      state.fetching = true
    })
    .addCase(modifyPage.fulfilled, state => {
      state.fetching = false
    })
    .addCase(modifyPage.rejected, (state, action) => {
      state.fetching = false
      const error = action.payload
      if (error !== undefined) {
        state.alert = {
          show: true,
          variant: 'danger',
          alertBody: error.message
        }
      }
    })
    .addCase(copyPage.pending, state => {
      state.fetching = true
    })
    .addCase(copyPage.fulfilled, state => {
      state.fetching = false
    })
    .addCase(copyPage.rejected, (state, action) => {
      state.fetching = false
      const error = action.payload
      if (error !== undefined) {
        state.alert = {
          show: true,
          variant: 'danger',
          alertBody: error.message
        }
      }
    })
    .addCase(deletePage.pending, state => {
      state.fetching = true
    })
    .addCase(deletePage.fulfilled, state => {
      state.fetching = false
      state.selectedIds = []
    })
    .addCase(deletePage.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(fetchLayoutResult.pending, state => {
      state.fetching = true
    })
    .addCase(fetchLayoutResult.fulfilled, (state, action) => {
      state.layoutResult = action.payload
      state.fetching = false
    })
    .addCase(fetchLayoutResult.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(exportLayout.pending, (state, action) => {
      state.fetching = true
    })
    .addCase(exportLayout.fulfilled, (state, action) => {
      downloadFulfilled(state, action)
      state.fetching = false
    })
    .addCase(exportLayout.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
})

export const selectPageItem = state => state.page

export const {
  selectColumn,
  selectPage,
  selectId,
  unselectId,
  openModal,
  closeModal,
  closeAlert,
  setSearchCondition,
  clearLayoutResult,
  dismissError
} = pageSlice.actions
