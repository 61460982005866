import { createSelector, createStructuredSelector } from 'reselect'
import moment from 'moment'

import {
  selectPageItem
} from '../page/pageSlice'
import {
  selectSubLayoutItem
} from '../subLayout/subLayoutSlice'

// page
export const selectPageErrors = (state) => state.page.errors
export const selectPageFetching = (state) => state.page.fetching
export const selectPageLayoutResultPresence = (state) => state.page.layoutResult != null
export const selectPageExportFilename = (state) => {
  const layoutable = state.article.layoutable
  if (layoutable == null) {
    return ''
  }
  const issueDate = moment(layoutable.issueDate).format('YYYYMMDD')
  const filename = `${layoutable.media.name}_${issueDate}_${layoutable.pageNumber}_${layoutable.draftNumber}.zip`
  return filename
}

// sub layout
export const selectSubLayoutErrors = (state) => state.subLayout.errors
export const selectSubLayoutFetching = (state) => state.subLayout.fetching
export const selectSubLayoutResultPresence = (state) => state.subLayout.layoutResult != null
export const selectSubLayoutExportFilename = (state) => {
  const layoutable = state.article.layoutable
  if (layoutable == null) {
    return ''
  }
  const issueDate = moment(layoutable.issueDate).format('YYYYMMDD')
  const filename = `${layoutable.media.name}_${issueDate}_${layoutable.name}_${layoutable.draftNumber}.zip`
  return filename
}

// layoutable
export const chooseLayoutableSelector = (layoutableType) =>
  layoutableType === 'Page'
    ? selectPageItem
    : layoutableType === 'SubLayout'
      ? selectSubLayoutItem
      : undefined

export const selectLayoutIndex = (state) => state.article.layoutable?.layoutIndex || 0
export const selectLayoutableId = (state) => state.article.layoutable?.id

export const selectLayoutablePresence = (state) => state.article?.layoutable != null
export const selectLayoutableUniqueness = createStructuredSelector({
  layoutableId: state => state.article.layoutable?.id,
  layoutIndex: state => state.article.layoutable?.layoutIndex
})
export const selectLayoutableStatus = (state) => state.article.layoutable?.status
export const selectLayoutableLockVersion = (state) => state.article.layoutable?.lockVersion
export const selectLayoutableAssigneeId = (state) => state.article.layoutable?.assignee?.id

// articles
export const selectArticlesFetching = (state) => state.article.fetching
export const selectArticleDataPresence = (state) => state.article?.data != null
export const selectArticleOpenedModal = (state) => state.article?.openedModal
export const selectArticleIds = createSelector(
  [(state) => state.article.data],
  (articles) => articles.map((article) => article.id)
)
export const selectArticleLockings = createSelector(
  [(state) => state.article.data],
  (articles) => articles.map((article) => article.layoutLocked),
  {
    memoizeOptions: {
      resultEqualityCheck: (a, b) =>
        Array.isArray(a) && Array.isArray(b) && a.length === b.length &&
        a.every((el, idx) => el === b[idx])
    }
  }
)

// TODO: make this custom hook
const selectArticleData = state => state.article.data
export const makeArticleDataSelectorByArticleId = () => {
  return createSelector(
    [
      selectArticleData,
      (_1, articleId) => articleId
    ],
    (articleData, articleId) => {
      return articleData.find(elem => articleId === elem.id)
    }
  )
}

// layout
export const selectNumberOfLayoutResults = (state, layoutableType) => {
  const layoutResults = layoutableType === 'Page'
    ? selectPageItem(state)?.layoutResult.layoutResults
    : selectSubLayoutItem(state)?.layoutResult?.layoutResults
  return layoutResults?.length || 0
}

// TODO: make this custom hook
export const makeLayoutSelectorByLayoutableAndArticleId = (layoutableType) => {
  return createSelector(
    [
      state => {
        return layoutableType === 'Page'
          ? selectPageItem(state)?.layoutResult
          : selectSubLayoutItem(state)?.layoutResult
      },
      (_1, layoutIndex) => layoutIndex,
      (_1, _2, articleId) => articleId
    ],
    (layoutResult, layoutIndex, articleId) => {
      return layoutResult.layoutResults
        ?.[layoutIndex]?.articles?.find(elem => `article-${articleId}` === elem.id)
    }
  )
}

// TODO: make this custom hook
export const makeNumberOfMissLayoutSelectorByLayoutIndex = (layoutableType) => {
  return createSelector(
    [
      state => {
        return layoutableType === 'Page'
          ? selectPageItem(state)?.layoutResult
          : selectSubLayoutItem(state)?.layoutResult
      },
      (_1, layoutIndex) => layoutIndex
    ],
    (layoutResult, layoutIndex) => {
      return layoutResult.layoutResults
        ?.[layoutIndex]?.articles?.filter(elem => elem.areas.length === 0)?.length
    }
  )
}

// for preview
