/* eslint-env browser */
import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  Stack
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ErrorModal
} from '../common'
import {
  shouldShowExperimentalFeatures,
  selectAuth
} from '../auth'
import {
  fetchLayoutResult as PageFetchLayoutResult,
  exportLayout as PageExportLayout
} from '../page/pageAPI'
import {
  fetchLayoutResult as SubLayoutFetchLayoutResult,
  exportLayout as SubLayoutExportLayout
} from '../subLayout/subLayoutAPI'
import {
  clearLayoutResult as PageClearLayoutResult,
  dismissError as PageDismissError
} from '../page/pageSlice'
import {
  clearLayoutResult as SubLayoutClearLayoutResult,
  dismissError as SubLayoutDismissError
} from '../subLayout/subLayoutSlice'
/*
import {
  isLayoutViewableStatus
} from '../article'
import {
  selectArticlesLayoutable
} from '../article/articleSlice'
*/
import {
  fetchArticles,
  getLayoutableBaseUrl
} from '../article/articleAPI'

import {
  LayoutPreview
} from './LayoutPreview'
import {
  ObjectTree
} from './ObjectTree'
import {
  ObjectProperty
} from './ObjectProperty'
import {
  BASE_HEIGHT,
  HEADER_HEIGHT
} from './common'
import {
  selectLayoutablePresence,
  selectLayoutIndex,
  selectPageErrors,
  selectPageLayoutResultPresence,
  selectPageExportFilename,
  selectSubLayoutErrors,
  selectSubLayoutResultPresence,
  selectSubLayoutExportFilename,
  selectArticleDataPresence,
  selectArticlesFetching
} from './layoutSlice'
import moment from 'moment'
moment.locale('ja')

const LayoutViewer = ({ ...props }) => {
  // オブジェクトツリー、プレビュー描画、オブジェクトツリーの3点セット
  const {
    layoutableType, layoutableId, layoutPresenceSelector,
    showExperimentalFeatures, currentUser
  } = props
  const layoutResultPresence = useSelector(layoutPresenceSelector)
  const layoutablePresence = useSelector(selectLayoutablePresence)

  if (!layoutResultPresence || !layoutablePresence) {
    return
  }

  const initialLayoutIndex = useSelector(selectLayoutIndex)
  const [layoutIndex, setLayoutIndexLocal] = useState(initialLayoutIndex)
  const [selectedElementsSelector, setSelectedElementsSelector] = useState(undefined)
  const [hoveredElementsSelector, setHoveredElementsSelector] = useState(undefined)
  const setLayoutIndex = (index) => {
    setLayoutIndexLocal(index)
    setSelectedElementsSelector(undefined)
    setHoveredElementsSelector(undefined)
  }

  const handleChangeOfSelected = useCallback((e) => {
    setSelectedElementsSelector(e.target.getAttribute('selector'))
  })

  const handleChangeOfHovered = useCallback((e) => {
    setHoveredElementsSelector(e.target.getAttribute('selector'))
  })

  const handleClearOfHovered = useCallback((e) => {
    setHoveredElementsSelector(undefined)
  })

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={2} style={{ overflowY: 'auto', maxHeight: `calc(${BASE_HEIGHT} - ${HEADER_HEIGHT})` }}>
            <ObjectTree
              layoutableType={layoutableType}
              layoutIndex={layoutIndex}
              selectedElementsSelector={selectedElementsSelector}
              hoveredElementsSelector={hoveredElementsSelector}
              onClickOfItems={handleChangeOfSelected}
              onMouseEnterOfItems={handleChangeOfHovered}
              onMouseLeaveOfItems={handleClearOfHovered}
            />
          </Col>
          <Col sm={8} style={{ overflowY: 'auto', maxHeight: `calc(${BASE_HEIGHT} - ${HEADER_HEIGHT})` }}>
            <LayoutPreview
              layoutableType={layoutableType}
              layoutIndex={layoutIndex}
              setLayoutIndex={setLayoutIndex}
              selectedElementsSelector={selectedElementsSelector}
              hoveredElementsSelector={hoveredElementsSelector}
              onClickOfSegments={handleChangeOfSelected}
              onMouseEnterOfSegments={handleChangeOfHovered}
              onMouseLeaveOfSegments={handleClearOfHovered}
            />
          </Col>
          <Col sm={2} style={{ overflowY: 'auto', maxHeight: `calc(${BASE_HEIGHT} - ${HEADER_HEIGHT})` }}>
            <ObjectProperty
              layoutableType={layoutableType}
              layoutableId={layoutableId}
              currentUser={currentUser}
              layoutIndex={layoutIndex}
              contentSelector={selectedElementsSelector}
              showExperimentalFeatures={showExperimentalFeatures}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export const LayoutPreviewScreen = ({ layoutableType = 'Page' }) => {
  // プレビューページの全画面
  const {
    layoutableId, fetchLayoutResult, clearLayoutResult, errorsSelector,
    layoutPresenceSelector, exportFilenameSelector, exportLayout
  } = layoutableType === 'Page'
    ? {
        layoutableId: parseInt(useParams().pageId),
        fetchLayoutResult: PageFetchLayoutResult,
        clearLayoutResult: PageClearLayoutResult,
        errorsSelector: selectPageErrors,
        layoutPresenceSelector: selectPageLayoutResultPresence,
        exportFilenameSelector: selectPageExportFilename,
        exportLayout: PageExportLayout
      }
    : layoutableType === 'SubLayout'
      ? {
          layoutableId: parseInt(useParams().subLayoutId),
          fetchLayoutResult: SubLayoutFetchLayoutResult,
          clearLayoutResult: SubLayoutClearLayoutResult,
          errorsSelector: selectSubLayoutErrors,
          layoutPresenceSelector: selectSubLayoutResultPresence,
          exportFilenameSelector: selectSubLayoutExportFilename,
          exportLayout: SubLayoutExportLayout
        }
      : {}

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { currentUser } = useSelector(selectAuth)
  const showExperimentalFeatures = shouldShowExperimentalFeatures()

  const layoutResultPresence = useSelector(layoutPresenceSelector)
  const articlesPresence = useSelector(selectArticleDataPresence)
  const errors = useSelector(errorsSelector)
  const layoutablePresence = useSelector(selectLayoutablePresence)
  const layoutableFetching = useSelector(selectArticlesFetching)

  const dismissError = layoutableType === 'Page'
    ? PageDismissError
    : layoutableType === 'SubLayout'
      ? SubLayoutDismissError
      : undefined

  const dataPresence = layoutResultPresence && articlesPresence && layoutablePresence

  useEffect(() => {
    dispatch(fetchLayoutResult({ id: layoutableId }))
    dispatch(fetchArticles({ layoutableType, layoutableId }))

    return () => {
      dispatch(clearLayoutResult())
    }
  }, [])

  const exportFilename = useSelector(exportFilenameSelector)
  const exportClickHandler = useCallback(() => {
    dispatch(exportLayout({ id: layoutableId, fileName: exportFilename }))
  }, [exportLayout, layoutableId, exportFilename])

  // const layoutable = useSelector(selectArticlesLayoutable)

  if (!dataPresence || layoutableFetching) {
    return <></>
  }

  const layoutableBaseUrl = getLayoutableBaseUrl({ layoutableType, layoutableId })

  // const layoutViewablePageDrafts = layoutable?.pageDrafts?.filter(p => p.id !== layoutableId && isLayoutViewableStatus(p.status)) ?? []
  // console.log({ layoutable, layoutViewablePageDrafts })

  return (
    <>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <Stack direction='horizontal' gap={1}>
        <div className='ms-auto' />
        {/* layoutableType === 'Page' && layoutViewablePageDrafts.length > 0 &&
          <Col sm='auto' className='px-0'>
            <DropdownButton
              size='sm' variant='outline-primary' title={
                <>
                  <FontAwesomeIcon icon='history' />
                  別ドラフト
                </>
              }
            >
              {layoutable?.pageDrafts?.map((p, pageIdx) => (
                <Dropdown.Item
                  key={p.draftNumber}
                  disabled={p.id === layoutable.id || !isLayoutViewableStatus(p.status)}
                  href={`/pages/${p.id}/layout`}
                >{`#${p.draftNumber}. ${moment(p.updatedAt).format('LLL')}`}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col> */}
        <ButtonGroup size='sm' className='m-1 ps-1'>
          <Button
            className='list-button'
            variant='outline-primary'
            onClick={() => navigate(`/${layoutableBaseUrl}/articles`)}
          >
            <FontAwesomeIcon icon='list' fixedWidth />出稿表
          </Button>
          <Button
            className='export-button'
            variant='outline-primary'
            disabled={!dataPresence}
            onClick={exportClickHandler}
          >
            <FontAwesomeIcon icon='file-export' fixedWidth />エクスポート
          </Button>
        </ButtonGroup>
      </Stack>
      <LayoutViewer
        layoutableType={layoutableType}
        layoutableId={layoutableId}
        layoutPresenceSelector={layoutPresenceSelector}
        currentUser={currentUser}
        showExperimentalFeatures={showExperimentalFeatures}
      />
    </>
  )
}
