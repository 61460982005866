/* eslint-env browser */
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Accordion,
  Alert,
  Col,
  Row,
  ListGroup
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  selectCommon
} from '../common'
import {
  selectArticleIds,
  makeLayoutSelectorByLayoutableAndArticleId,
  makeArticleDataSelectorByArticleId,
  makeNumberOfMissLayoutSelectorByLayoutIndex
} from './layoutSlice'

const ObjectTreeArticleItem = (props) => {
  const { layoutIndex, layoutableType, articleId } = props
  const { enumValues } = useSelector(selectCommon)

  const h6Style = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    fontSize: '1.0rem',
    fontWeight: 'bold'
  }

  const itemStyle = (isHovered, isSelected) => {
    return {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
      fontSize: '0.9rem',
      ...(isHovered) && {
        border: 'solid 1px'
      },
      ...(!isHovered) && {
        textDecoration: 'underline'
      },
      ...(isSelected) && {
        backgroundColor: '#fbb'
      }
    }
  }

  const selectLayout = useMemo(
    () => makeLayoutSelectorByLayoutableAndArticleId(layoutableType))
  const layout = useSelector(state => selectLayout(state, layoutIndex, articleId))

  const createElemSelector = (category, index = undefined) => {
    return index !== undefined
      ? `.${layout.id}.${category}.index${index}`
      : `.${layout.id}.${category}`
  }

  const selectArticle = useMemo(() => makeArticleDataSelectorByArticleId())
  const article = useSelector(state => selectArticle(state, articleId))
  const layoutLockCaution = article.layoutLocked ? <><FontAwesomeIcon icon='lock' /> </> : undefined
  if (layout === undefined) {
    return <></>
  }

  const missLayoutCaution = layout.areas.length === 0
    ? <><FontAwesomeIcon icon='triangle-exclamation' /> </>
    : undefined
  const typeString = enumValues.articleTypes[article.articleType]
  const title = article.headings.length > 0
    ? article.headings[0].content.replace(/(\n)/gm, '')
    : `${typeString} - ${article.filename || article.name || article.id}`

  const headings = layout.headingAreas.length > 0
    ? (
      <>
        <h6 style={h6Style}>見出し</h6>
        <ListGroup>
          {
            layout.headingAreas.map((headingArea, i) => {
              const headingTypeNames = { ...enumValues.headingTypes, heading: '通常見出し' }
              const selector = createElemSelector('heading', i)
              const isHovered = selector === props.hoveredElementsSelector
              const isSelected = selector === props.selectedElementsSelector
              const headingType = article.headings.find(ah => ah.id === headingArea?.id)?.headingType ?? 'heading'
              const title = headingTypeNames[headingType] ?? '不明な見出し'
              return (
                <ListGroup.Item
                  key={i} style={itemStyle(isHovered, isSelected)}
                  selector={selector}
                  onClick={props.onClickOfItems}
                  onMouseEnter={props.onMouseEnterOfItems}
                  onMouseLeave={props.onMouseLeaveOfItems}
                >
                  {title}
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
      )
    : undefined

  const preamble = ((article.preambleContent !== null) && layout.areas.length > 0)
    ? (
      <>
        <h6 style={h6Style}>前文</h6>
        <ListGroup>
          {
            layout.areas.slice(0, 1).map((_, i) => {
              const selector = createElemSelector('preamble', undefined)
              const isHovered = selector === props.hoveredElementsSelector
              const isSelected = selector === props.selectedElementsSelector
              return (
                <ListGroup.Item
                  key={i} style={itemStyle(isHovered, isSelected)}
                  selector={selector}
                  onClick={props.onClickOfItems}
                  onMouseEnter={props.onMouseEnterOfItems}
                  onMouseLeave={props.onMouseLeaveOfItems}
                >
                  前文エリア
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
      )
    : undefined

  const textStart = preamble !== undefined ? 1 : 0
  const textEnd = textStart + 1

  const segments = layout.areas.length > 0
    ? (
      <>
        <h6 style={h6Style}>本文</h6>
        <ListGroup>
          {
            layout.areas.slice(textStart, textEnd).map((_, i) => {
              const category = article.articleType
              const selector = createElemSelector(category, undefined)
              const isHovered = selector === props.hoveredElementsSelector
              const isSelected = selector === props.selectedElementsSelector
              return (
                <ListGroup.Item
                  key={i} style={itemStyle(isHovered, isSelected)}
                  selector={selector}
                  onClick={props.onClickOfItems}
                  onMouseEnter={props.onMouseEnterOfItems}
                  onMouseLeave={props.onMouseLeaveOfItems}
                >
                  本文エリア
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
      )
    : undefined

  const figures = ((article.figures.length > 0) && (layout.figures.length > 0))
    ? (
      <>
        <h6 style={h6Style}>図表</h6>
        <ListGroup>
          {
            article.figures.map((figure, i) => {
              const selector = createElemSelector('figure', i)
              const isHovered = selector === props.hoveredElementsSelector
              const isSelected = selector === props.selectedElementsSelector
              return (
                <ListGroup.Item
                  key={i} style={itemStyle(isHovered, isSelected)}
                  selector={selector}
                  onClick={props.onClickOfItems}
                  onMouseEnter={props.onMouseEnterOfItems}
                  onMouseLeave={props.onMouseLeaveOfItems}
                >
                  図表エリア {i + 1}
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
      )
    : undefined

  return (
    <Accordion>
      <Accordion.Item eventKey='{key}'>
        <Accordion.Header>{layoutLockCaution}{missLayoutCaution}{title}</Accordion.Header>
        <Accordion.Body>
          {headings}
          {preamble}
          {segments}
          {figures}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export const ObjectTree = ({ ...props }) => {
  // LayoutViewer以下のコンポーネントはレイアウトがフェッチされている前提で書いて良い
  const { layoutIndex, layoutableType } = props
  const articleIds = useSelector(selectArticleIds)

  const selectNumberOfMissLayout = useMemo(() => makeNumberOfMissLayoutSelectorByLayoutIndex(layoutableType))
  const numberOfMissLayout = useSelector(state => selectNumberOfMissLayout(state, layoutIndex))

  return (
    <>
      {numberOfMissLayout > 0 &&
        <Row>
          <Col>
            <Alert variant='warning' className='px-2 d-flex justify-content-center align-items-center m-0'>
              <FontAwesomeIcon icon='triangle-exclamation' />
              割り付けされなかった記事が{numberOfMissLayout}本あります。
            </Alert>
          </Col>
        </Row>}
      <Row>
        <Col>
          {
            articleIds.map((articleId) => {
              return (
                <ObjectTreeArticleItem
                  articleId={articleId}
                  layoutableType={layoutableType}
                  layoutIndex={layoutIndex}
                  key={articleId}
                  selectedElementsSelector={props.selectedElementsSelector}
                  hoveredElementsSelector={props.hoveredElementsSelector}
                  onClickOfItems={props.onClickOfItems}
                  onMouseEnterOfItems={props.onMouseEnterOfItems}
                  onMouseLeaveOfItems={props.onMouseLeaveOfItems}
                />
              )
            })
          }
        </Col>
      </Row>
    </>
  )
}
